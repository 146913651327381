import styled from "styled-components";
import {
	FONT_SIZE_16,
	COLOR_TEXT_PRIMARY_B2U,
	FONT_WT_HEAVY,
	FONT_WT_LIGHT,
	MOBILE_L_BP,
	MOBILE_M_BP,
	TABLET_BP,
	DESKTOP_L_BP,
	DESKTOP_M_BP,
	DESKTOP_SM_BP,
} from "@website-builder/utilities-index";

const getHeroBannerStyles = (preHeroBannerPadding) => {
	const sectionPadding = 18;
	const topPositioning = preHeroBannerPadding?.[0] || 72;
	const mobileTopPositioning = preHeroBannerPadding?.[1] || 72;
	const heroBannerPadding = 132;
	const mobileHeroBannerPadding = 92;
	return `
    .rebrand-section, .paid-rebrand-footer, .rebrand-footer {
      position: relative;
      top: ${topPositioning}px;
      @media only screen and (max-width: ${MOBILE_L_BP}px){
          top: ${mobileTopPositioning}px;
      }
    }
    .hero-banner-wr.up-banner-wrapper {
      padding-top: ${heroBannerPadding - sectionPadding - topPositioning}px;
      @media only screen and (max-width: ${MOBILE_L_BP}px){
          padding-top: 20px;
      }
    }
    
  `;
};

export const UniversityWrapper = styled.main`
	font-size: ${FONT_SIZE_16};
	color: ${COLOR_TEXT_PRIMARY_B2U};
	font-weight: ${FONT_WT_LIGHT};

	h1,
	h2,
	strong,
	b {
		font-weight: ${FONT_WT_HEAVY};
	}
	${({ preHeroBannerPadding }) => getHeroBannerStyles(preHeroBannerPadding)}

	.rich-text-wrapper *:not(:first-child) {
		margin-top: 1rem;
	}

	.main-wrapper {
		background-color: rgba(240, 240, 240, 0.38);

		.banner {
			min-height: 150px;
			background-color: var(--primary);

			@media only screen and (max-width: ${MOBILE_L_BP}px) {
				min-height: 90px;
			}
		}
	}

	.section-container:not(.rebrand-section) {
		margin-top: -70px;
		padding: 0 165px 35px 125px;
		display: flex;
		flex-flow: row nowrap;
		line-height: 1.5;

		@media only screen and (max-width: ${DESKTOP_M_BP}px) {
			margin-top: -70px;
			padding: 0;
			justify-content: center;
		}

		@media only screen and (max-width: ${DESKTOP_SM_BP}px) {
			margin-top: -70px;
			flex-flow: column nowrap;
			align-items: center;
		}

		@media only screen and (max-width: ${MOBILE_L_BP}px) {
			margin: 0;
			padding: 0 0 35px 0;
			flex-flow: column nowrap;
			align-items: center;
		}

		.left-section {
			width: calc(100% - 380px);
			margin-right: 80px;
			background-color: #fff;

			> section:nth-child(1) > div {
				padding-top: 35px;
			}

			@media only screen and (min-width: ${DESKTOP_M_BP +
				1}px) and (max-width: ${DESKTOP_L_BP}px) {
				width: 710px;
				margin-right: 50px;
			}

			@media only screen and (min-width: ${DESKTOP_SM_BP +
				1}px) and (max-width: ${DESKTOP_M_BP}px) {
				width: 710px;
				margin-right: 20px;
			}

			@media only screen and (max-width: ${DESKTOP_SM_BP}px) {
				margin-right: 0;
				margin-bottom: 35px;
				width: 100%;
			}

			@media only screen and (max-width: ${MOBILE_L_BP}px) {
				margin-right: 0;
				width: 100%;
			}
		}

		.right-section {
			flex: 0 0 300px;

			> section:not(:first-child).add-margin {
				margin-top: 30px;
			}

			@media only screen and (min-width: ${MOBILE_L_BP +
				1}px) and (max-width: ${DESKTOP_SM_BP}px) {
				width: 100%;
				padding: 0px 50px;
				order: -1;
			}

			@media only screen and (min-width: ${MOBILE_M_BP +
				1}px) and (max-width: ${MOBILE_L_BP}px) {
				width: 100%;
				padding: 20px 20px 0;
				order: -1;
			}

			@media only screen and (max-width: ${MOBILE_M_BP}px) {
				width: 300px;
				padding: 20px 0 0;
				order: -1;
			}
		}
	}
`;

export const BodyWrapper = styled.div`
	display: block;
	padding: ${({ padding }) => (padding ? padding : "18px 0")};
	@media only screen and (max-width: ${TABLET_BP}px) {
		display: ${(props) => (props.hide ? "none" : "block")};
	}
`;

export const LeftComponentWrapper = styled.div`
	display: block;
	padding: ${({ addPadding }) => (addPadding ? "18px 35px" : "18px 0")};
	${({ addMargin }) => (addMargin ? `margin-top: 30px;` : "")}

	@media only screen and (max-width: ${TABLET_BP}px) {
		display: ${(props) => (props.hide ? "none" : "block")};
	}
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		padding: ${({ addPadding }) => (addPadding ? "18px 15px" : "18px 0")};
	}
`;

export const RightComponentWrapper = styled.div`
	padding: ${({ addPadding, padding }) => (addPadding ? padding : "0")};
	margin-bottom: ${({ addMargin }) => (addMargin ? "30px" : "0px")};
	display: block;

	@media only screen and (max-width: ${TABLET_BP}px) {
		display: ${(props) => (props.hide ? "none" : "block")};
	}
	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		padding: ${({ addPadding }) => (addPadding ? "18px 15px" : "0")};
	}
`;
